:root {
    --normal-cup: 300px;
    --small-cup: 150px;

    --normal-plate-width: 500px;
    --normal-plate-height: 200px;

    --small-plate-width: 250px;
    --small-plate-height: 100px;

    --normal-pulsate-font: 24px;
    --small-pulsate-font: 16px;

    --normal-text-mt: 200px;
    --small-text-mt: 150px;
    --normal-text-mb: 15px;

    --small-handle-right: -35px;
    --small-handle-top: 20px;
    --small-handle-width: 55px;
    --small-handle-height: 70px;
    --small-handle-border: 12px solid #dcdcdc;
    --small-handle-border-left: 12px solid transparent;
    --small-handle-border-bottom: 12px solid transparent;

    --normal-handle-right: -70px;
    --normal-handle-top: 40px;
    --normal-handle-width: 110px;
    --normal-handle-height: 140px;
    --normal-handle-border: 25px solid #dcdcdc;
    --normal-handle-border-left: 25px solid transparent;
    --normal-handle-border-bottom: 25px solid transparent;
}

.home {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 920px;
}

/* cup - start */
.container {
    position: relative;
    top: 50px;
}

.cup {
    margin-top: 100%;
    position: relative;
    height: var(--normal-cup);
    width: var(--normal-cup);
    background: linear-gradient(to right, #f9f9f9, #d9d9d9);
    border-bottom-right-radius: 45%;
    border-bottom-left-radius: 45%;
}

.top {
    position: absolute;
    top: -30px;
    left: 0px;
    width: 100%;
    height: 60px;
    background: linear-gradient(to right, #f9f9f9, #d9d9d9);
    border-radius: 50%;
}

.circle {
    position: absolute;
    top: 5px;
    left: 10px;
    width: calc(100% - 20px);
    height: 50px;
    background: linear-gradient(to left, #f9f9f9, #d9d9d9);
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
}

.tea {
    position: absolute;
    top: 20px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #c57e65, #e28462);
    border-radius: 50%;
    box-sizing: border-box;
}

.handle {
    position: absolute;
    right: var(--normal-handle-right);
    top: var(--normal-handle-top);
    width: var(--normal-handle-width);
    height: var(--normal-handle-height);
    border: var(--normal-handle-border);
    border-left: var(--normal-handle-border-left);
    border-bottom: var(--normal-handle-border-bottom);
    border-radius: 50%;
    transform: rotate(42deg);
}

.plate {
    position: absolute;
    bottom: -50px;
    left: 50%;
    width: var(--normal-plate-width);
    height: var(--normal-plate-height);
    border-radius: 50%;
    background: linear-gradient(to right, #f9f9f9, #e7e7e7);
    transform: translateX(-50%);
    box-shadow: 0 35px 35px rgba(0, 0, 0, 0.2);
}

.plate::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    background: linear-gradient(to left, #f2f2f2, #e7e7e7);
}

.plate::after {
    content: '';
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    border-radius: 50%;
    background: radial-gradient(rgba(0, 0, 0, 0.2), transparent, transparent);
}

.vapour {
    position: relative;
    display: flex;
    z-index: 1;
    padding: 0 20px;
}

.vapour span {
    position: relative;
    bottom: 50px;
    display: block;
    margin: 0px 2px 50px;
    min-width: 8px;
    height: 120px;
    background: #ffdb8d;
    border-radius: 50%;
    animation: cupAnimate 5s linear infinite;
    opacity: 0;
    filter: blur(8px);
    animation-delay: calc(var(--i) * -0.5s);
}

.vapour div {
    position: relative;
    bottom: 50px;
    display: block;
    margin: 0px 2px 50px;
    min-width: 8px;
    height: 120px;
    background: #ecd0d0;
    border-radius: 50%;
    animation: cupAnimate2 5s linear infinite;
    opacity: 0;
    filter: blur(8px);
    animation-delay: calc(var(--i) * -0.5s);
}

@keyframes cupAnimate {
    0% {
        transform: translateY(0) scaleX(1);
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    50% {
        transform: translateY(-150px) scaleX(5);
    }
    95% {
        opacity: 0;
    }
    100% {
        transform: translateY(-350px) scaleX(10);
    }
}

@keyframes cupAnimate2 {
    0% {
        transform: translateY(0) scaleX(1);
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    50% {
        transform: translateY(-150px) scaleX(5);
    }
    95% {
        opacity: 0;
    }
    100% {
        transform: translateY(-350px) scaleX(10);
    }
}

@media screen and (max-width: 700px) {
    .cup {
        height: var(--small-cup);
        width: var(--small-cup);
        margin-top: 60%;
    }

    .plate {
        width: var(--small-plate-width);
        height: var(--small-plate-height);
        bottom: -41px;
        box-shadow: 0 17px 17px rgba(0, 0, 0, 0.2);
    }

    .handle {
        right: var(--small-handle-right);
        top: var(--small-handle-top);
        width: var(--small-handle-width);
        height: var(--small-handle-height);
        border: var(--small-handle-border);
        border-left: var(--small-handle-border-left);
        border-bottom: var(--small-handle-border-bottom);
    }

    .vapour span,
    .vapour div {
        bottom: 25px;
        margin: 0px 1px 25px;
        min-width: 4px;
        height: 60px;
        filter: blur(4px);
    }
}

@media screen and (max-height: 500px) {
    .cup {
        height: 75px;
        width: 75px;
        margin-top: 0%;
    }

    .plate {
        width: 125px;
        height: 50px;
        bottom: -18px;
        box-shadow: 0 17px 17px rgba(0, 0, 0, 0.2);
    }

    .top {
        position: absolute;
        top: -14px;
        left: 0px;
        width: 100%;
        height: 30px;
        background: linear-gradient(to right, #f9f9f9, #d9d9d9);
        border-radius: 50%;
    }

    .circle {
        position: absolute;
        top: 5px;
        left: 10px;
        width: calc(100% - 20px);
        height: 25px;
        background: linear-gradient(to left, #f9f9f9, #d9d9d9);
        border-radius: 50%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .tea {
        position: absolute;
        top: 10px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, #c57e65, #e28462);
        border-radius: 50%;
        box-sizing: border-box;
    }

    .handle {
        right: -17px;
        top: 10px;
        width: 27.5px;
        height: 35px;
        border: 6px solid #dcdcdc;
        border-left: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }

    .vapour {
        padding: 0;
    }

    .vapour span,
    .vapour div {
        bottom: 12px;
        margin: 0px 1px 12px;
        min-width: 2px;
        height: 30px;
        filter: blur(3px);
    }
}
/* cup - end */

/* text animation - start */
.animate-text {
    margin-top: var(--normal-text-mt);
    white-space: nowrap;
    margin-bottom: var(--normal-text-mb);
}

.animate-text span {
    display: inline-block;
    animation: slideIn 1s ease forwards;
    opacity: 0;
}

.pulsate {
    font-size: var(--normal-pulsate-font);
    animation: pulsate 1.5s infinite;
    text-shadow: 0 0 0px rgba(25, 118, 210, 0.15);
}

@keyframes pulsate {
    0% {
        transform: scale(1);
        text-shadow: 0 0 0px rgba(25, 118, 210, 0.15);
    }
    50% {
        transform: scale(1.005);
        text-shadow: 0 0 5px rgba(25, 118, 210, 0.3);
    }
    100% {
        transform: scale(1);
        text-shadow: 0 0 0px rgba(25, 118, 210, 0.15);
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: 700px) {
    .pulsate {
        font-size: var(--small-pulsate-font);
    }
    .animate-text {
        margin-top: var(--small-text-mt);
    }
}

@media screen and (max-height: 500px) {
    .animate-text {
        margin-top: 100px;
    }
}
/* text animation - end */
