/* login page - start */
.ring {
    position: relative;
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ring i {
    position: absolute;
    inset: 0;
    border: 2px solid #b0d89b;
    transition: 0.5s;
}

.ring i:nth-child(1) {
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    animation: animate 6s linear infinite;
}

.ring i:nth-child(2) {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: animate 4s linear infinite;
}

.ring i:nth-child(3) {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: animate2 10s linear infinite;
}

.ring:hover i.grn {
    border: 6px solid #00ff0a;
    filter: drop-shadow(0 0 20px #00ff0a);
}

.ring:hover i.rd {
    border: 6px solid #ff0057;
    filter: drop-shadow(0 0 20px #ff0057);
}

.ring:hover i.yllw {
    border: 6px solid #fffd44;
    filter: drop-shadow(0 0 20px #fffd44);
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate2 {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.login {
    position: absolute;
    width: 250px;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    top: -10px;
}

.login .inputBx {
    position: relative;
}

.inputBx input {
    position: relative;
    width: 82.4%;
    padding: 12px 20px;
    background: transparent;
    color: #000;
    box-shadow: none;
    outline: none;
}
/* login page - end */
