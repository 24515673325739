body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #fff #e1e3cd;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #e1e3cd;
}

*::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 0px none #fff;
}

.active-tab {
    text-decoration: underline;
    font-weight: bold;
}
